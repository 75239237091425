.rectangle{
    background-color: #90a4ae;
    width: 60%;
    height: 95%;
    top: 1rem;
    bottom: 1rem;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);;
    z-index: -1;
}
.avisocookies{
    background-color: #cfd8dc;
    top: 10rem;
    position: fixed;
    left: 50%;
    width: 80%;
    transform: translateX(-50%);;
    z-index: 99;
    border-radius: 20px;
}


.rectangle.rectact{
    z-index: 998;
}

.glass{
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}
