@media (min-width: 200px) {
    .UpDownClass {
        position:fixed;
        margin-top: 350px;
        margin-left: 75%;
        z-index:2;
        animation: zoom 3s infinite;
    }
    @keyframes zoom {
        0% {
            transform: scale(0.5); /* Aumento de escala */            
          }
          50% {
            transform:scale(0.6);
          }
          100% {
            transform:scale(0.5);
          }
    }

    .neon {
        font-family: 'Neonderthaw', cursive;
        font-size:25px;
        text-shadow: 0 -1px 0 #f00, 
        0 2px 0 #f00, 
        0 4px 0 #60f, 
        -2px -2px 15px #f06, 
        2px -2px 15px #f06, 
        -2px 2px 15px #f06, 
        2px 2px 15px #f06;
     }
    .neon:hover{
        color:crimson; /* Cambio de color de fondo */
        transform: scale(1.2); /* Aumento de escala */
    }     
}

@media (min-width: 992px){
    .UpDownClass {
        position:fixed;
        margin-top: 350px;
        margin-left: 90%;
        z-index:2;
        transform: scale(1); /* Escala inicial */
        color: orangered;
        animation: zoom 3s infinite;
        
    }
    
    @keyframes zoom {
        0% {
            transform: scale(1); /* Aumento de escala */            
          }
          50% {
            transform:scale(1.1);
          }
          100% {
            transform:scale(1);
          }
    }

    .neon {
        font-family: 'Neonderthaw', cursive;
        font-size:30px;
        text-shadow: 0 -1px 0 #f00, 
        0 2px 0 #f00, 
        0 4px 0 #60f, 
        -2px -2px 15px #f06, 
        2px -2px 15px #f06, 
        -2px 2px 15px #f06, 
        2px 2px 15px #f06;
     }
    .neon:hover{
        color:crimson; /* Cambio de color de fondo */
        transform: scale(1.2); /* Aumento de escala */
    }
}