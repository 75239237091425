@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


.center{
    width: auto;
    height: auto;
    transform:scale(1)    
}

.carousel .carousel-item .linea-division {
    width:350px;
    height: 4px;
    position: relative;
    background-color: #f06;
}
.subtitulo{
    width:25%;
    border-radius: 15px;
    color: #fff;
    align-tracks: center;
    margin-top: 5px;
    margin-left: 5px;
    font-size:25px;
    text-shadow: 0 -1px 0 #f00, 
    0 2px 0 #f00, 
    0 4px 0 #60f, 
    -2px -2px 15px #f06, 
    2px -2px 15px #f06, 
    -2px 2px 15px #f06, 
    2px 2px 15px #f06;
 }


.carousel .carousel-item {
    display: flex;
    position:relative;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    user-select: none;
    width: 300px;
    height: 350px;
    transition: transform 0.5s ease;
    transform:scale(0.65)
} 

/* .slick-slide para visualizar el carrusell */
.slick-slide {
    transition: transform 1s ease;
    width: auto;
    height: auto;
    align-content: center;
  }
  
.slick-center {
    transform: scale(1.5); /* Aumenta el tamaño del slide central */

  }

.carousel .carousel-item .imgItem {
    width: 350px;
    height: 350px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 15px;
} 

.carousel .carousel-item .visitarPrd  {
    position:absolute;
    margin-top: 70%;
    margin-left: 110px;
    margin-right: 0%;
    text-align: center;

}

