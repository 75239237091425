*{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

/* .stictheme {
  position: absolute;
  z-index: 99;
  margin-left: 70%;
  margin-top: 70px;
  width: 122px;
} */

 /* Small devices (landscape phones, 576px and up) */
@media (min-width: 300px) {
  body{
    background-color:whitesmoke;
    align-content: center;
  }
  .bar{
    max-width: 100%;
    border-color: #90a4ae;
    background-color:gainsboro;
  }
  .search{
    max-width: 100%;
    border-color: #90a4ae;
    
  }
  .card  {
    position: relative;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 5%;
    display: block;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    box-shadow: grey;
    /* background-color:rgb(218, 218, 218); */
    border: 0.1px solid #0000;
    border-color: #90a4ae;
    /* margin: 10px; */
    overflow:hidden; /*se muestra solo en el recuadro*/
    max-width: 30%;
    min-width: 300px;
    max-height: 600px;
    min-height: 600px;
    padding: 10px;
  }
  .txtsearch{
    display: none;
  }

  .stictheme {
    position: absolute;
    z-index: 99;
    margin-left: 25%;
    margin-top: 15px;
    width: 122px;
  }

  .logobar {
    background-size: contain;
    max-width: 50px;
  }

}

 /* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  body{
    background-color:whitesmoke;
    align-content: center;
  }
  .containerApp {
    padding-right: 10px;
    padding-left: 10px;
    align-content: center;
  }
  .bar{
    max-width: 100%;
    border-color: #90a4ae;
    background-color:gainsboro;
  }
  .search{
    display: inline-flex;
    max-width: 95%;
    border-color: #90a4ae;
  }
  .card {
    position: relative;
    border-radius: 7px;
    box-shadow: grey;
    /* background-color:rgb(218, 218, 218); */
    border: 0.1px solid #0000;
    border-color: #90a4ae;
    margin: 10px;
    overflow:hidden; /*se muestra solo en el recuadro*/
    max-width: 30%;
    min-width: 300px;
    max-height: 600px;
    min-height: 600px;
    display: inline-block;
  }
  .stictheme {
    position: absolute;
    z-index: 99;
    margin-left: 75%;
    margin-top: 20px;
    width: 122px;
  }
  .logobar {
    background-size: contain;
    max-width: 50px;
  }
  .MARG1{
    padding-left: 65%;
  }

}

 /* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  body{
    /* background-color:whitesmoke; */
    align-content: center;
  }
  .containerApp {
    padding-right: 70px;
    padding-left: 70px;
    align-content: center;
  }
  
  .bar{
    max-width: 95%;
    /* margin-top: 20px; */
    border-color: #90a4ae;
    /* background-color:gainsboro; */
  }
  .search{
    display: inline-flex;
    max-width: 95%;
    border-color: #90a4ae;
  }
  .card {
    position: relative;
    border-radius: 7px;
    box-shadow: grey;
    /* background-color:rgb(218, 218, 218); */
    border: 0.1px solid #0000;
    border-color: #90a4ae;
    margin: 10px;
    overflow:hidden; /*se muestra solo en el recuadro*/
    max-width: 30%;
    min-width: 300px;
    max-height: 600px;
    min-height: 600px;
    display: inline-block;
  }
  .txtsearch{
    display: inline-block;
  }
/* para el .stictheme */
  .stictheme {
    position: absolute;
    z-index: 99;
    margin-left: 60%;
    margin-top: 80px;
    width: 122px;  
  }
  .MARG1{
    padding-left: 45%;
  }
}
/* para el .stictheme */
@media (min-width: 1259px) {
  .stictheme {
    position: absolute;
    z-index: 99;
    margin-left: 40%;
    margin-top: 13px;
    width: 122px;  
  }
}

input{
  color: gray;
  font-size: 1.2rem;
  width: 100%;
}

.logobar {
  background-size: contain;
  max-width: 50px;
}

.card img {
  align-content: center;
  align-items: center;
  max-width: 100%;
  max-height: 250px;
  min-height: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  
  
}

/* .card p {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  overflow-x: hidden; 
  overflow-y: scroll; 
  max-height: 100px;
} */

.conten{
  max-height: 280px;
  min-height: 280px;
  overflow: hidden;
}
.conten p {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  overflow-x: hidden; 
  overflow-y:inherit; 
  max-height: 100px;
}


.btn {
  
  text-align: center;
  padding: 5px;
  box-shadow: #0000;
  /* border: 0.1px solid black; */
  border-radius: 10px;
  bottom:10px; 
}
.btn:hover{
  transform: scale(1.2);
}
